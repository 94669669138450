import React from "react";
import FaqQuestion from "./faqQuestion";

// FaqSection component to display a section of FAQ questions with a heading
const FaqSection = ({ heading, questionArr }) => {
  return (
    <div>
      {/* Display the section heading */}
      <h1 className="text-2xl text-black font-inter font-bold my-12">
        {heading}
      </h1>
      {/* Map over the array of questions and render a FaqQuestion component for each */}
      {questionArr.map((question, index) => (
        // Each FaqQuestion is wrapped in a div with a unique key based on the index
        <div key={index}>
          <FaqQuestion question={question} />
        </div>
      ))}
    </div>
  );
};

export default FaqSection;
