import React from "react";
import Login from "../views/login/login";

const AuthLogin = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="max-w-[1200px] w-full">
        {/* Render the Login component */}
        <Login />
      </div>
    </div>
  );
};

export default AuthLogin;
