// output faqs data

export const outputFaqArr = [
  {
    question: "How can I save my input text or Witscript's output text?",
    answer:
      "Currently the Witscript app doesn't provide a way for users to save any input or output text. To save any text, copy/paste it into a document outside of the app and then save that document.",
    answer2:
      "Be sure to copy/paste any output text you want to save before you generate new output text. Your previously generated text can't be recovered once it disappears.",
  },
  {
    question: 'What are those "possible responses" that Witscript outputs?',
    answer:
      "Each of those possible responses is an attempt by Witscript to come up with a good joke. Witscript uses a different algorithm to generate each possible response.",
    answer2:
      "Witscript suggests possible responses the way human writers pitch possible jokes to a human head writer: some of their pitches will make no sense, some will almost be a joke, and some will be good jokes. The best of those jokes will be delivered to their intended audience.",
    answer3:
      "Witscript's possible responses allow you to use the system as a joke-writing assistant. Reading the responses, you might see a near-miss that you can turn into a good joke with a little editing. Or a possible response might suggest a new direction you can take as you're writing a joke.",
  },
  {
    question: 'What does "Witscript responds" mean?',
    answer:
      'The text you see after "Witscript responds" is the possible response that Witscript has decided is the funniest. If Witscript were the humor module in a chatbot, it\'s the response that the chatbot would deliver to the user.',
    answer2:
      "Because humor is subjective to some extent, you may decide that one of the other possible responses is funnier. Of course, you're free to use whatever output you like.",
  },
  {
    question: "How can I get even funnier responses out of Witscript?",
    answer:
      "You can input the same text again and again; just keeping clicking \"Get Witscript's responses.\" Witscript will output new responses each time, with minimal duplication. You may prefer one of them to the ones you've already seen.",
    answer2:
      "Or you can try rewording your input text, maybe shortening it, lengthening it, clarifying it or substituting synonyms for some of the words. A rewording can lead Witscript to focus on different details of the input text, resulting in fresh approaches to generating jokes.",
    answer3:
      "Finally, you can always input completely new text. Any human comedy writer can tell you that some topics are harder to turn into jokes than others.",
  },
];
