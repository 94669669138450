import React, { useState } from "react";
import Eye from "../../icon/eye";
import EyeSlash from "../../icon/eyeSlash";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyInput from "../../components/myInput";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/axios-instance";
import EmailVerify from "./emailVerify";

// Define validation schema for form fields using Yup
const SignupSchema = Yup.object().shape({
  userName: Yup.string()
    .matches(
      /^[a-z0-9]+$/,
      "Only lowercase letters and numbers are allowed, with no spaces"
    )
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  cPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

const SingUp = () => {
  const navigate = useNavigate();
  // State variables
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");

  // Function to close modal
  function closeModal() {
    setEmail("");
    setIsOpen(false);
  }

  // Function to open modal with provided email
  function openModal(em) {
    setEmail(em);
    setIsOpen(true);
  }

  return (
    <div className="px-4 md:px-8 mt-8 mb-4 flex flex-col items-center">
      <div className="max-w-[320px] w-full">
        <h1 className="text-4xl text-black font-bold mb-8 text-center">
          Create Account
        </h1>
        {/* Formik form for handling form values and validation */}
        <Formik
          initialValues={{
            userName: "",
            email: "",
            password: "",
            cPassword: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={async (values) => {
            // Check if the user has agreed to terms and conditions
            if (!isChecked) {
              toast.error(
                "Please agree to the Terms and Conditions and Privacy Policy"
              );
              return;
            }
            try {
              // POST request to register user
              const resp = await fetch(`${BASE_URL}/registration/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: values.email,
                  password1: values.password,
                  password2: values.cPassword,
                }),
              });
              const jsonResponse = await resp.json();
              if (
                jsonResponse?.email?.detail.includes(
                  "Email verification is pending"
                )
              ) {
                // If registration successful, resend verification email
                const resp2 = await fetch(
                  `${BASE_URL}/registration/resend-email/`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      email: values.email,
                    }),
                  }
                );

                if (!resp2.ok) {
                  const errorResponse = await resp2.json();
                  const errorMessage = Object.values(errorResponse)[0];
                  console.error(
                    `Error: ${resp2.status} - ${resp2.statusText} - ${errorMessage}`
                  );
                  toast.error(errorMessage, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                  });
                  throw new Error(
                    `Error: ${resp2.status} - ${resp2.statusText}`
                  );
                }
              } else if (
                jsonResponse?.email?.detail.includes(
                  "user is already registered"
                )
              ) {
                toast.error("User with this email already exist.", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  theme: "colored",
                  progress: undefined,
                });
                throw new Error(`Error: ${resp.status} - ${resp.statusText}`);
              } else if (!resp.ok) {
                // console.log(jsonResponse);
                const errorMessage = Object.values(jsonResponse)[0][0];
                console.error(
                  `Error: ${resp.status} - ${resp.statusText} - ${errorMessage}`
                );
                toast.error(errorMessage, {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  theme: "colored",
                  progress: undefined,
                });
                throw new Error(`Error: ${resp.status} - ${resp.statusText}`);
              }

              // Open email verification modal
              openModal(values.email);
            } catch (error) {
              console.error("Error fetching data:", error);
              return "Some Error occurs. Please try again.";
            }
          }}
        >
          {({ errors, touched, handleChange }) => (
            <Form>
              {/* Custom input component */}
              <MyInput
                name="userName"
                placeholder="Name"
                onChange={handleChange}
                type="text"
                errors={errors.userName}
                touched={touched.userName}
              />
              <MyInput
                name="email"
                placeholder="Email"
                onChange={handleChange}
                type="text"
                errors={errors.email}
                touched={touched.email}
              />
              <div className="my-4 relative">
                <MyInput
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  errors={errors.password}
                  touched={touched.password}
                />
                {/* Toggle password visibility */}
                <p
                  className={`absolute inset-y-0 right-3 w-fit transform ${
                    errors.password && touched.password
                      ? "top-1/2 -translate-y-[65%]"
                      : "top-1/2 -translate-y-1/2"
                  } `}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Eye /> : <EyeSlash />}
                </p>
              </div>
              <div className="my-4 relative">
                <MyInput
                  name="cPassword"
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  type={showCPassword ? "text" : "password"}
                  errors={errors.cPassword}
                  touched={touched.cPassword}
                />
                {/* Toggle confirm password visibility */}
                <p
                  className={`absolute inset-y-0 right-3 w-fit transform ${
                    errors.cPassword && touched.cPassword
                      ? "top-1/2 -translate-y-[65%]"
                      : "top-1/2 -translate-y-1/2"
                  } `}
                  onClick={() => setShowCPassword(!showCPassword)}
                >
                  {showCPassword ? <Eye /> : <EyeSlash />}
                </p>
              </div>
              {/* Terms and conditions checkbox */}
              <div className="flex items-start mt-4">
                <input
                  type="checkbox"
                  id="agreeCheckbox"
                  checked={isChecked}
                  className="mt-1"
                  onChange={() => setIsChecked(!isChecked)}
                />
                <label htmlFor="agreeCheckbox" className="ml-2 text-sm">
                  I have read and agree to the{" "}
                  <a
                    href="https://witscript.com/wp-content/uploads/2024/09/Terms-Conditions-Revised-09-25-2024.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline text-blue-600"
                  >
                    Terms and Conditions
                  </a>{" "}
                  and the{" "}
                  <a
                    href="https://witscript.com/wp-content/uploads/2024/09/Privacy-Policy-Revised-09-25-2024.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline text-blue-600"
                  >
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>
              {/* Sign up button */}
              <button
                type="submit"
                className="px-8 py-2 mt-5 w-full bg-[#1F4DC4] text-white rounded-full text-lg font-medium hover:shadow-[7px_5px_4px_0px_#dcdcdc]"
              >
                Sign Up
              </button>
            </Form>
          )}
        </Formik>
        <p className="w-full text-center text-lg font-medium mt-2">OR</p>
        {/* Login button */}
        <div className="flex justify-center">
          <button
            onClick={() => navigate("/auth/login")}
            className="mt-2 px-8 py-2 w-[50%] bg-[#1F4DC4] text-white rounded-full text-lg font-medium hover:shadow-[7px_5px_4px_0px_#dcdcdc]"
          >
            Login
          </button>
        </div>
        {/* Email verification modal */}
        <EmailVerify isOpen={isOpen} closeModal={closeModal} email={email} />
      </div>
    </div>
  );
};

export default SingUp;
