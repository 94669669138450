import React from "react";
import Header from "../layout/header";

// Layout component to provide a common layout structure
const Layout = ({ children }) => {
  return (
    <div className="w-full flex flex-col items-center">
      {/* display the header component */}
      <div className="relative w-full">
        <Header />
      </div>
      {/* display below header content */}
      {children}
    </div>
  );
};

export default Layout;
