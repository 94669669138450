import React from "react";

// BillingInfo component to display billing information with a title and value
const BillingInfo = ({ title, value }) => {
  return (
    <div>
      <h6 className="text-gray3 text-xs font-inter uppercase">{title}</h6>
      <p className="text-black text-lg font-medium">{value}</p>
    </div>
  );
};

export default BillingInfo;
