import React, { useState } from "react";
import Eye from "../../icon/eye";
import EyeSlash from "../../icon/eyeSlash";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyInput from "../../components/myInput";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/axios-instance";
import { useLoginUser } from "../../context/loginUser";
import { getToken } from "../../api/getToken";

// Define schema for form validation
const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  cPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

const UpdatePassword = () => {
  // State variables to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  // get active user token
  const { token } = useLoginUser();

  return (
    <div className="w-full max-w-[500px]">
      {/* Heading for the password update section */}
      <p className="text-lg font-inter font-semibold text-black mb-8">
        Change your password here.
      </p>
      {/* Formik form for password update */}
      <Formik
        initialValues={{
          password: "",
          cPassword: "",
        }}
        validationSchema={SignupSchema} // Apply validation schema
        onSubmit={async (values) => {
          const accessToken = await getToken(token); // Get access token
          if (accessToken !== "error") {
            try {
              // Send password update request to the server
              const resp = await fetch(`${BASE_URL}/password/change/`, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  new_password1: values.password,
                  new_password2: values.cPassword,
                }),
              });

              // Handle server response
              if (!resp.ok) {
                const errorResponse = await resp.json();
                const errorMessage = Object.values(errorResponse)[0];
                console.error(
                  `Error: ${resp.status} - ${resp.statusText} - ${errorMessage}`
                );
                // Display error message as toast notification
                toast.error(errorMessage, {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  theme: "colored",
                  progress: undefined,
                });
                throw new Error(`Error: ${resp.status} - ${resp.statusText}`);
              }

              // Display success message as toast notification
              toast.success("Password Updated Successfully", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
              });
            } catch (error) {
              console.error("Error fetching data:", error);
              return "Some Error occurs. Please try again.";
            }
          } else {
            // Display session expiration message as toast notification
            toast.error(
              "Your session has expired. Please logout, then login again.",
              {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
              }
            );
          }
        }}
      >
        {({ errors, touched, handleChange }) => (
          <Form>
            {/* New password input */}
            <h3 className="text-black text-lg font-inter font-medium">
              New Password
            </h3>
            <div className="my-4 relative">
              {/* Custom input component with toggle button for password visibility */}
              <MyInput
                name="password"
                placeholder="Please enter new password"
                onChange={handleChange}
                type={showPassword ? "text" : "password"}
                errors={errors.password}
                touched={touched.password}
              />
              {/* Toggle button for password visibility */}
              <p
                className={`absolute inset-y-0 right-3 w-fit transform ${
                  errors.password && touched.password
                    ? "top-1/2 -translate-y-[65%]"
                    : "top-1/2 -translate-y-1/2"
                } `}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Eye /> : <EyeSlash />}
              </p>
            </div>
            {/* Confirm new password input */}
            <h3 className="text-black text-lg font-inter font-medium">
              Confirm New Password
            </h3>
            <div className="my-4 relative">
              <MyInput
                name="cPassword"
                placeholder="Please retype new password"
                onChange={handleChange}
                type={showCPassword ? "text" : "password"}
                errors={errors.cPassword}
                touched={touched.cPassword}
              />
              {/* Toggle button for confirm password visibility */}
              <p
                className={`absolute inset-y-0 right-3 w-fit transform ${
                  errors.cPassword && touched.cPassword
                    ? "top-1/2 -translate-y-[65%]"
                    : "top-1/2 -translate-y-1/2"
                } `}
                onClick={() => setShowCPassword(!showCPassword)}
              >
                {showCPassword ? <Eye /> : <EyeSlash />}
              </p>
            </div>
            {/* Submit button */}
            <div className="flex justify-end">
              <button
                type="submit"
                className="px-16 py-3 bg-[#2962FF] text-white rounded-full text-lg font-medium hover:shadow-[5px_5px_4px_0px_#dcdcdc]"
              >
                Change Password
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdatePassword;
