import React, { useState, useEffect, useCallback } from "react";
import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";

// NavLinksShow component to render navigation links
const NavLinksShow = ({ item, index, check }) => {
  // Get URL search parameters and location using react-router hooks
  const [params] = useSearchParams();
  const location = useLocation();

  // useCallback to memoize the active index calculation function
  const getActiveIndex = useCallback(() => {
    // Get the current pathname from location
    const pathname = location.pathname;
    // Check if the "faq" parameter is set to "true"
    if (params?.get("faq") === "true") {
      return 1; // FAQ link is active
    } else if (pathname === "/") {
      return 0; // Home link is active
    } else if (pathname === "/account") {
      return 2; // Account link is active
    } else {
      return -1; // No link is active
    }
  }, [location, params]); // Dependencies for the useCallback

  // State to manage the active index
  const [active, setActive] = useState(0);

  // useEffect to update the active index when location or search parameters change
  useEffect(() => {
    setActive(getActiveIndex());
  }, [location, getActiveIndex, params]);

  return (
    // RouterLink component to navigate to the specified link
    <RouterLink
      to={`${item.link.toLowerCase()}`}
      onClick={() => setActive(index)} // Set the active index on click
      className={`${check ? "flex justify-center" : ""}`}
    >
      {/* Render the link title with appropriate styles based on the active index */}
      <p
        className={`text-3xl font-bold cursor-pointer font-inter ${
          index === active ? "text-[#22CC00] underline" : "text-black"
        }`}
      >
        {item.title}
      </p>
    </RouterLink>
  );
};

export default NavLinksShow;
