import React, { useState } from "react";
import Layout from "../layout/layout";
import UpdatePassword from "../views/account/updatePassword";
import Billing from "../views/account/billing";
import Menu from "../views/account/menu";

const Account = () => {
  // State to manage which section of the account page is open
  const [open, setOpen] = useState(0);

  return (
    // Container to center the content horizontally and vertically
    <div className="flex justify-center items-center w-full">
      {/* Layout component for consistent page layout */}
      <Layout>
        {/* Container for the account page content */}
        <div className="max-w-[1200px] w-full flex items-center lg:items-start lg:flex-row flex-col gap-8 mt-32 px-4 sm:px-8">
          {/* Menu component for navigation within the account page */}
          <Menu open={open} setOpen={setOpen} />
          {/* Conditional rendering based on the value of the 'open' state */}
          <div className="w-full flex justify-center">
            {/* Render Billing component component if 'open' is 0, else render UpdatePassword*/}
            {open === 0 && <Billing />}
            {open === 1 && <UpdatePassword />}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Account;
