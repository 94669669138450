import React, { useState } from "react";
import { jokesArr } from "../../constants/jokes"; // Importing jokesArr constant
import { questionsArr } from "../../constants/questions"; // Importing questionsArr constant
import { useLoginUser } from "../../context/loginUser";
import { getToken } from "../../api/getToken"; // Importing getToken function
import { BASE_URL } from "../../utils/axios-instance";
import { toast } from "react-toastify";

// Write component
const Write = () => {
  // State variables
  const [text, setText] = useState(""); // State for user input text
  const [error, setError] = useState(""); // State for error message
  const [loading, setLoading] = useState(false); // State for loading indicator
  const { user, setUser, token } = useLoginUser(); // get login user and setUser

  // Function to fetch user data
  const fetchData = async () => {
    const accessToken = await getToken(token); // Get access token
    if (accessToken !== "error") {
      // If access token is valid
      try {
        const resp = await fetch(`${BASE_URL}/user/`, {
          // Fetch user data
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!resp.ok) {
          // If response is not okay
          const errorResponse = await resp.json(); // Get error response
          const errorMessage = Object.values(errorResponse)[0]; // Get error message
          console.error(
            `Error: ${resp.status} - ${resp.statusText} - ${errorMessage}`
          );
          toast.error(errorMessage, {
            // Show error message using toast
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "colored",
            progress: undefined,
          });
          return true;
        }

        const json = await resp.json(); // Get JSON response
        setUser(json); // Set user data
        return false;
      } catch (error) {
        console.error("Error fetching data:", error); // Log error if fetching data fails
        return true;
      }
    } else {
      return true;
    }
  };

  // Function to handle generating script
  const handleGenerateScript = async () => {
    const isExpire = await fetchData(); // Fetch user data
    if (!isExpire) {
      // If user session is not expired
      if (user.payment_status === "active") {
        // If user's payment status is active
        // Retrieving HTML elements
        const heading1 = document.getElementById("heading1");
        const response1 = document.getElementById("response1");
        const heading2 = document.getElementById("heading2");
        const response2 = document.getElementById("response2");
        if (text.trim() === "") {
          // If user input is empty
          response1.innerHTML = "Witscript responds here"; // Display default response
          heading1.innerHTML = "";
          response2.innerHTML = "";
          heading2.innerHTML = "";
          setError("Please type in something for Witscript."); // Set error message
        } else {
          setError(""); // Clear error message
          setLoading(true); // Set loading to true
          if (response1) {
            // If response1 element exists
            const response = await getResponse(); // Get response from Witscript
            if (response !== "error") {
              // If response is not an error
              const witscriptResponse = response
                .split("WITSCRIPT RESPONDS:")
                .map((line) => line.trim()); // Split response into possible responses and Witscript's response
              response1.innerHTML = witscriptResponse[0].replace(/\n/g, "<br>"); // Display possible responses
              heading1.innerHTML = "POSSIBLE RESPONSES:";
              response2.innerHTML = witscriptResponse[1]; // Display Witscript's response
              heading2.innerHTML = "WITSCRIPT RESPONDS:";
            } else {
              // If response is an error
              response1.innerHTML = "Witscript responds here"; // Display default response
              heading1.innerHTML = "";
              response2.innerHTML = "";
              heading2.innerHTML = "";
            }
          }
          setLoading(false); // Set loading to false
        }
      } else {
        // If user's payment status is not active
        toast.error("Please go to 'Account' to subscribe.", {
          // Show error message using toast
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored",
          progress: undefined,
        });
      }
    } else {
      // If user session is expired
      toast.error(
        "Your session has expired. Please logout, then login again.", // Show error message using toast
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored",
          progress: undefined,
        }
      );
    }
  };

  // Function to handle user input change
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setError(""); // Clear error message
    setText(inputValue); // Set text state
  };

  // Function to get response from Witscript
  const getResponse = async () => {
    try {
      const accessToken = await getToken(token); // Get access token
      if (accessToken !== "error") {
        // If access token is valid
        let formData = new FormData(); // Create FormData object
        formData.append("text", text); // Append text to FormData object
        const resp = await fetch(`${BASE_URL}/generator/`, {
          // Fetch response from generator endpoint
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData, // Set body of the request as formData
        });

        if (!resp.ok) {
          // If response is not okay
          const errorText = await resp.text(); // Get error text
          console.error(
            `Error: ${resp.status} - ${resp.statusText} - ${errorText}`
          );
          if (errorText.includes("Please type in at least 5 words")) {
            // Set error message
            setError("Please type in at least 5 words.");
            return "error"; // Return error
          } else {
            // Set error message
            setError("Please type in no more than 25 words.");
            return "error"; // Return error
          }
        }

        const json = await resp.json(); // Get JSON response
        const witscriptResponse = json.data
          .replace("POSSIBLE RESPONSES:", "")
          .split(/(?=[A-Z]\))/)
          .map((line) => line.trim())
          .join("\n"); // Split response into possible responses and format it

        return witscriptResponse; // Return formatted response
      } else {
        toast.error(
          "Your session has expired. Please logout, then login again.", // Show error message using toast
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "colored",
            progress: undefined,
          }
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error); // Log error if fetching data fails
      return "Witscript responds here"; // Return default response
    }
  };

  return (
    <div id="write" className="px-4 md:px-8 mt-32">
      <div className="flex flex-col lg:flex-row">
        {/* Left side section */}
        <div className="lg:w-[30%] p-4 rounded-[12px] bg-gray-100">
          <h1 className="text-lg font-bold mb-4 font-inter">
            Write original jokes fast with AI.
          </h1>
          <ul className="pl-4 list-disc flex-1">
            {jokesArr.map((joke, index) => (
              <li key={index} className="text-lg mb-4">
                {joke}
              </li>
            ))}
          </ul>
        </div>
        {/* Right side section */}
        <div className="mt-8 lg:mt-0 lg:ml-8 lg:w-[70%] flex flex-col">
          {/* Textarea for user input */}
          <textarea
            value={text}
            onChange={handleChange}
            className="w-full rounded-[12px] h-[230px] sm:h-[200px] md:h-[160px] xl:h-[130px] overflow-hidden hover:shadow-[10px_5px_4px_0px_#dcdcdc] border-2 border-black p-4 focus:text-start bg-[#D5FFCC] placeholder-black text-lg break-words"
            type="text"
            placeholder="Type your sentence for Witscript here."
          />
          {error && <p className="text-red-500 mt-2">{error}</p>}
          {/* Button for generating script */}
          <div className="flex justify-center my-8">
            <button
              onClick={handleGenerateScript}
              disabled={loading}
              className={`${
                user.payment_status === "active"
                  ? "bg-[#2962FF]"
                  : "bg-[#2962FF] cursor-not-allowed"
              } ${
                loading ? "cursor-not-allowed" : ""
              } px-8 py-4 w-[300px] break-words font-inter text-white rounded-full text-lg font-medium hover:shadow-[7px_5px_4px_0px_#dcdcdc]`}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full border-t-4 border-white h-8 w-8"></div>
                </div>
              ) : (
                "Get Witscript’s responses"
              )}
            </button>
          </div>
          {/* Response section */}
          <p
            className={`w-full min-h-[200px] border-2 hover:shadow-[10px_5px_4px_0px_#dcdcdc] border-black rounded-[12px] p-4 mt-4 text-lg`}
          >
            <span id="heading1" className="text-inter font-bold block"></span>
            <span id="response1" className="block">
              Witscript responds here
            </span>
            <span
              id="heading2"
              className="text-inter font-bold block mt-5"
            ></span>
            <span id="response2" className="block"></span>
          </p>
        </div>
      </div>
      {/* Additional information section */}
      <div className="bg-[#F0FFF0] px-4 py-4 mt-8 rounded-[12px]">
        <div>
          <h1 className="text-inter text-xl mb-4 font-bold">
            What kind of sentences can I type in?
          </h1>
          <h1 className="text-lg font-bold">
            Your input sentence can be a statement or a question, like these:
          </h1>
          <ul className="pl-4 list-disc flex-1">
            {questionsArr.map((joke, index) => (
              <li key={index} className="text-lg">
                {joke}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h1 className="text-lg font-bold mt-8 text-justify">
            Like a human comedian, Witscript might occasionally deliver
            something offensive that doesn't represent the views of Twenty Lane
            Media, LLC.
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Write;
