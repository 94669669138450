import React from "react";
import { useNavigate } from "react-router-dom";

// Getstarted component
const GetStartedView = () => {
  // Hook for navigation
  const navigate = useNavigate();

  return (
    <div className="px-4 md:px-8 mt-12 flex flex-col items-center">
      {/* Container for logo and buttons */}
      <div className="max-w-[320px] w-full">
        {/* Logo */}
        <img src="/assets/logo.png" alt="" className="w-[300px] mb-8" />

        {/* Login button */}
        <button
          onClick={() => navigate("/auth/login")} // Navigate to login page on click
          className="px-8 py-2 mt-4 w-full bg-[#1F4DC4] text-white rounded-full text-lg font-medium hover:shadow-[7px_5px_4px_0px_#dcdcdc]"
        >
          Login
        </button>

        {/* Or separator */}
        <p className="w-full text-center text-lg font-medium mt-2">OR</p>

        {/* Sign up button */}
        <button
          onClick={() => navigate("/auth/signup")} // Navigate to sign up page on click
          className="mt-2 px-8 py-[6px] w-full bg-[#1F4DC4] text-white rounded-full text-lg font-medium hover:shadow-[7px_5px_4px_0px_#dcdcdc]"
        >
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default GetStartedView;
