import React from "react";
import { Accounts } from "../../constants/accounts"; // Importing the list of accounts from constants

const Menu = ({ open, setOpen }) => {
  return (
    <div className="bg-[#EDF2F6] px-12 py-12 h-fit rounded-[10px] flex flex-col gap-8 w-fit">
      {/* Mapping through the list of accounts */}
      {Accounts.map((item, index) => (
        // Render each account as a clickable heading
        <h2
          key={index}
          onClick={() => {
            // When clicked, set the open state to the index of the clicked item
            setOpen(index);
          }}
          // Applying styles based on whether the account is currently open
          className={`cursor-pointer text-2xl font-inter ${
            open === index
              ? "text-[#2962FF] font-bold"
              : "font-semibold text-[#707B9E]"
          }`}
        >
          {item} {/* Displaying the account name */}
        </h2>
      ))}
    </div>
  );
};

export default Menu;
