import React, { useState } from "react";
import Eye from "../../icon/eye";
import EyeSlash from "../../icon/eyeSlash";
import MyInput from "../../components/myInput";
import { useLoginUser } from "../../context/loginUser";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/axios-instance";

const Login = () => {
  const navigate = useNavigate();
  const { setUser, setToken } = useLoginUser();
  const [showPassword, setShowPassword] = useState(false);
  const [forget, setForget] = useState(false);

  // Initial form values
  const initialValues = {
    email: "",
    password: forget ? undefined : "",
  };

  // Form validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: forget ? undefined : Yup.string().required("Required"),
  });

  return (
    <div className="px-4 md:px-8 mt-12 flex flex-col items-center">
      <div className="max-w-[320px] w-full">
        <img src="/assets/logo.png" alt="" className="w-[300px] mb-8" />
        <div>
          {/* Conditional rendering based on forget state */}
          {!forget ? (
            <h1 className="text-4xl text-black font-bold mt-8 text-center mb-8">
              Welcome Back
            </h1>
          ) : (
            <h1 className="text-[13px] text-black font-medium mb-4">
              Enter the email address associated with your account and we'll
              send you a link to reset your password.{" "}
            </h1>
          )}
          {/* Formik form */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              try {
                let resp;
                // Different API endpoint for password reset
                if (forget) {
                  resp = await fetch(`${BASE_URL}/password/reset/`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      email: values.email,
                    }),
                  });
                } else {
                  resp = await fetch(`${BASE_URL}/login/`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      email: values.email,
                      password: values.password,
                    }),
                  });
                }

                if (!resp.ok) {
                  const errorResponse = await resp.json();
                  const errorMessage = Object.values(errorResponse)[0][0];
                  console.error(
                    `Error: ${resp.status} - ${resp.statusText} - ${errorMessage}`
                  );
                  // Display error toast message
                  toast.error(errorMessage, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                  });
                  throw new Error(`Error: ${resp.status} - ${resp.statusText}`);
                }

                const json = await resp.json();
                if (!forget) {
                  // Set user data and token on successful login
                  setUser(json.user);
                  setToken(json.refresh);
                  navigate("/");
                  // Display success toast message
                  toast.success("Login successful.", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                  });
                } else {
                  // Display email sent toast message for password reset
                  toast.success("Email sent. You can now close this page.", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                  });
                }
              } catch (error) {
                console.error("Error fetching data:", error);
                return "Some Error occurs. Please try again.";
              }
            }}
          >
            {({ errors, touched, handleChange }) => (
              <Form>
                {/* Input fields */}
                <MyInput
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  type="text"
                  errors={errors.email}
                  touched={touched.email}
                />
                {/* Conditional rendering based on forget state */}
                {!forget && (
                  <div className="mt-4 relative">
                    <MyInput
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      type={showPassword ? "text" : "password"}
                      errors={errors.password}
                      touched={touched.password}
                    />
                    {/* Toggle password visibility */}
                    <p
                      className={`absolute inset-y-0 right-3 w-fit transform ${
                        errors.password && touched.password
                          ? "top-1/2 -translate-y-[65%]"
                          : "top-1/2 -translate-y-1/2"
                      } `}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Eye /> : <EyeSlash />}
                    </p>
                  </div>
                )}
                {/* Conditional rendering based on forget state */}
                {!forget && (
                  <p
                    onClick={() => setForget(true)}
                    className="text-right text-sm font-medium text-[#1F4DC4] cursor-pointer"
                  >
                    Forgot Password?
                  </p>
                )}
                {/* Submit button */}
                <button
                  type="submit"
                  className={`${
                    forget ? "mt-8" : ""
                  } px-8 py-2 mt-4 w-full bg-[#1F4DC4] text-white rounded-full text-lg font-medium hover:shadow-[7px_5px_4px_0px_#dcdcdc]`}
                >
                  {forget ? "Continue" : "Login"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
        {/* Sign Up button */}
        <p className="w-full text-center text-lg font-medium mt-2">OR</p>
        <div className="flex justify-center">
          <button
            onClick={() => navigate("/auth/signup")}
            className="mt-2 px-8 py-2 w-[50%] bg-[#1F4DC4] text-white rounded-full text-lg font-medium hover:shadow-[7px_5px_4px_0px_#dcdcdc]"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
