import { BASE_URL } from "../utils/axios-instance";

// Define an async function to get a new access token using a refresh token
export const getToken = async (token) => {
  try {
    // Make a POST request to the token refresh endpoint
    const resp = await fetch(`${BASE_URL}/token/refresh/`, {
      method: "POST", // Specify the request method as POST
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
      },
      body: JSON.stringify({
        refresh: token, // Send the refresh token in the request body
      }),
    });

    // Check if the response status is not OK
    if (!resp.ok) {
      // Parse the error response as JSON
      const errorResponse = await resp.json();
      // Extract the error message from the response
      const errorMessage = Object.values(errorResponse)[0];
      // Log the error details to the console
      console.error(
        `Error: ${resp.status} - ${resp.statusText} - ${errorMessage}`
      );

      // Return a string indicating an error occurred
      return "error";
      // throw new Error(`Error: ${resp.status} - ${resp.statusText}`);
    }

    // Parse the successful response as JSON
    const json = await resp.json();
    // Return the new access token from the response
    return json.access;
  } catch (error) {
    // Log any errors that occur during the fetch process
    console.error("Error fetching data:", error);
    // Return a generic error message
    return "Some Error occurs. Please try again.";
  }
};
