// below jokes content
export const questionsArr = [
  "A polar bear at an Ohio zoo gave birth to twins.",
  "You may have heard that genius is 1% inspiration and 99% perspiration.",
  "What movie won Best Picture in 1996?",
  "There's no trash pickup on Monday because it's Lincoln's Birthday.",
  "What would a doctor in an examination room say to Mr. Potato Head?",
  "Marriage is a lot like going to Costco.",
  "What's a good birthday present for someone who loves knitting?",
];
