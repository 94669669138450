export function formatDate(timestamp) {
  // Convert the timestamp to milliseconds (since it's in seconds)
  const date = new Date(timestamp * 1000);

  // Define options for formatting the date
  const options = { year: "numeric", month: "short", day: "numeric" };

  // Format the date using toLocaleDateString
  return date.toLocaleDateString("en-US", options);
}
