import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginUser } from "../context/loginUser";

// AuthGuard component to protect routes based on user authentication
export const AuthGuard = ({ children }) => {
  // Access the current user state from the login user context
  const { user } = useLoginUser();
  // Get the navigate function from react-router-dom to programmatically navigate
  const navigate = useNavigate();

  // useEffect hook to perform side effects when the component mounts or when user or navigate changes
  useEffect(() => {
    // If the user is not logged in, navigate to the /getStarted page
    if (user === "") {
      navigate("/getStarted");
    }
  }, [user, navigate]); // The effect depends on user and navigate, and runs when they change

  // Render the child components if the user is logged in
  return <>{children}</>;
};
