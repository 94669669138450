import React, { useState, useRef, useEffect } from "react";

// FaqQuestion component to display a question with an accordion-style answer
const FaqQuestion = ({ question }) => {
  // State to track if the accordion is open or closed
  const [isOpen, setIsOpen] = useState(false);
  // Ref to access the content div element
  const contentRef = useRef(null);
  // State to manage the height of the content for smooth transition
  const [height, setHeight] = useState("0px");

  // Function to toggle the accordion open/closed state
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  // useEffect hook to adjust the content height when isOpen state changes
  useEffect(() => {
    // Set the height based on whether the accordion is open or closed
    setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : "0px");
  }, [isOpen]);

  return (
    <div className="mb-4 bg-gray-100 p-4 rounded-[10px]">
      {/* Header section of the accordion with a clickable toggle */}
      <div
        onClick={toggleAccordion}
        className="flex justify-between gap-3 cursor-pointer"
      >
        {/* Question button */}
        <button className="text-lg font-bold text-left focus:outline-none mb-4">
          {question.question}
        </button>
        {/* Toggle indicator */}
        <p className="text-lg font-bold">{isOpen ? "-" : "+"}</p>
      </div>

      {/* Content section of the accordion with smooth transition and height control */}
      <div
        ref={contentRef}
        style={{ maxHeight: height }}
        className={`overflow-hidden transition-all duration-500 ease-in-out ${
          isOpen ? "opacity-100" : "opacity-0"
        }`}
      >
        {/* Display answer first paragraph */}
        <p className="text-lg">{question.answer}</p>
        {/* Conditionally render additional answers paragraphs if they exist */}
        {question.answer2 && <p className="text-lg mt-4">{question.answer2}</p>}
        {question.answer3 && <p className="text-lg mt-4">{question.answer3}</p>}
      </div>
    </div>
  );
};

export default FaqQuestion;
