import React, { useState } from "react";
import Eye from "../../icon/eye";
import EyeSlash from "../../icon/eyeSlash";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyInput from "../../components/myInput";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/axios-instance";

// Define validation schema for password change
const PasswordChangeSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  cPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  // get uid and token from link params
  const { uid, token } = useParams();
  // variables for password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  return (
    <div className="px-4 md:px-8 mt-8 mb-4 flex flex-col items-center">
      <div className="max-w-[320px] w-full">
        <img src="/assets/logo.png" alt="" className="w-[300px] mb-8" />
        <h1 className="text-3xl text-black font-bold mb-8 mt-8 text-center">
          Reset Password
        </h1>
        {/* Formik form for password reset */}
        <Formik
          initialValues={{
            password: "",
            cPassword: "",
          }}
          validationSchema={PasswordChangeSchema}
          onSubmit={async (values) => {
            // call password reset api
            try {
              const resp = await fetch(`${BASE_URL}/password/reset/confirm/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  new_password1: values.password,
                  new_password2: values.cPassword,
                  uid: uid,
                  token: token,
                }),
              });

              if (!resp.ok) {
                const errorResponse = await resp.json();
                const errorMessage = Object.values(errorResponse)[0][0];
                console.error(
                  `Error: ${resp.status} - ${resp.statusText} - ${errorMessage}`
                );
                // display error message toast if occur
                toast.error(errorMessage, {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  theme: "colored",
                  progress: undefined,
                });
                throw new Error(`Error: ${resp.status} - ${resp.statusText}`);
              }

              // Navigate to login page after successful password change
              navigate("/auth/login");
              toast.success("Password changed successfully", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
              });
            } catch (error) {
              console.error("Error fetching data:", error);
              return "Some Error occurs. Please try again.";
            }
          }}
        >
          {({ errors, touched, handleChange }) => (
            <Form>
              {/* New password input */}
              <div className="my-4 relative">
                <MyInput
                  name="password"
                  placeholder="New Password"
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  errors={errors.password}
                  touched={touched.password}
                />
                {/* Toggle button to show/hide password */}
                <p
                  className={`absolute inset-y-0 right-3 w-fit transform ${
                    errors.password && touched.password
                      ? "top-1/2 -translate-y-[65%]"
                      : "top-1/2 -translate-y-1/2"
                  } `}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Eye /> : <EyeSlash />}
                </p>
              </div>
              {/* Confirm password input */}
              <div className="my-4 relative">
                <MyInput
                  name="cPassword"
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  type={showCPassword ? "text" : "password"}
                  errors={errors.cPassword}
                  touched={touched.cPassword}
                />
                {/* Toggle button to show/hide confirm password */}
                <p
                  className={`absolute inset-y-0 right-3 w-fit transform ${
                    errors.cPassword && touched.cPassword
                      ? "top-1/2 -translate-y-[65%]"
                      : "top-1/2 -translate-y-1/2"
                  } `}
                  onClick={() => setShowCPassword(!showCPassword)}
                >
                  {showCPassword ? <Eye /> : <EyeSlash />}
                </p>
              </div>
              {/* Submit button */}
              <button
                type="submit"
                className="px-8 py-2 mt-5 w-full bg-[#1F4DC4] text-white rounded-full text-lg font-medium hover:shadow-[7px_5px_4px_0px_#dcdcdc]"
              >
                Update
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
