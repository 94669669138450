import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import AccountSignUp from "./pages/authSignUp";
import AuthLogin from "./pages/authLogin";
import Account from "./pages/account";
import GetStarted from "./pages/getStarted";
import Password from "./pages/password";
import { LoginUserProvider } from "./context/loginUser";
import { AuthGuard } from "./auth/auth";
import { GuestGuard } from "./auth/guest-guard";

function App() {
  return (
    // Provide the LoginUser to the entire app
    <LoginUserProvider>
      <BrowserRouter>
        <Routes>
          {/* Protected route: Only authenticated users can access the Home page */}
          <Route
            path="/"
            element={
              <AuthGuard>
                <Home />
              </AuthGuard>
            }
          />
          {/* Protected route: Only authenticated users can access the Account page */}
          <Route
            path="/account"
            element={
              <AuthGuard>
                <Account />
              </AuthGuard>
            }
          />
          {/* Public route: Only guest users (not authenticated) can access the Get Started page */}
          <Route
            path="/getstarted"
            element={
              <GuestGuard>
                <GetStarted />
              </GuestGuard>
            }
          />
          {/* Public route: Only guest users can access the Sign Up page */}
          <Route
            path="/auth/signup"
            element={
              <GuestGuard>
                <AccountSignUp />
              </GuestGuard>
            }
          />
          {/* Public route: Only guest users can access the Login page */}
          <Route
            path="/auth/login"
            element={
              <GuestGuard>
                <AuthLogin />
              </GuestGuard>
            }
          />
          {/* Public route: Only guest users can access the Password reset page using UID and token */}
          <Route
            path="/:uid/:token"
            element={
              <GuestGuard>
                <Password />
              </GuestGuard>
            }
          />
        </Routes>
      </BrowserRouter>
    </LoginUserProvider>
  );
}

export default App;
