import { Transition, Dialog } from "@headlessui/react";
import { Fragment } from "react";
import Bar3 from "../icon/bar3";
import { navLinks } from "../constants/navLinks";
import UserLogout from "./userLogout";
import NavLink from "./navLink";

// Sidebar for mobile screens
const MobileTabs = ({ open, setOpen }) => {
  // toggler for sidebar
  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <div className="xl:hidden flex items-center">
      {/* Bar3 icon to open sidebar in mobile view */}
      <div className="xl:hidden cursor-pointer" onClick={toggleMenu}>
        <Bar3 />
      </div>
      {/* Apply smooth transition when open and close sidebar bar */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10 xl:hidden" onClose={setOpen}>
          {/* Background overlay for sidebar */}
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden pt-32 ">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-[300px] pl-10">
                {/* Sidebar panel animation */}
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    {/* Close button */}
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 md:-ml-10 md:pr-4">
                        <button
                          className="rounded-md text-black1 hover:text-black1 focus:outline-none "
                          onClick={() => setOpen(false)}
                        >
                          <svg strokeWidth="1.5" stroke="currentColor">
                            <path d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </Transition.Child>
                    {/* Sidebar content */}
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="relative flex-1">
                        <div className="absolute inset-0 grid">
                          <div className="flex flex-col gap-y-4">
                            {/* Logo */}
                            <div className="flex items-center justify-center mt-6">
                              <img
                                src="/assets/logo.png"
                                alt=""
                                className="w-[220px]"
                              />
                            </div>
                            {/* Navigation links */}
                            <div className="flex flex-col gap-y-4 px-4">
                              {navLinks.map((item, index) => {
                                return (
                                  <div key={index}>
                                    {/* NavLink component */}
                                    <NavLink
                                      item={item}
                                      index={index}
                                      check={false}
                                    />
                                  </div>
                                );
                              })}
                              {/* Logout button */}
                              <UserLogout />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default MobileTabs;
