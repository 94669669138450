import React from "react";
import FaqSection from "../../components/faqSection"; // Importing FaqSection component
import { inputFaqArr } from "../../constants/inputFaq"; // Importing inputFaqArr constant
import { outputFaqArr } from "../../constants/outputFaq"; // Importing outputFaqArr constant

// FAQ component
const FAQ = () => {
  return (
    <div id="faq" className="p-4 mx-4 sm:mx-8 bg-white rounded-[12px] mt-20">
      {/* Title for FAQ section */}
      <h1 className="text-3xl text-black font-extrabold font-inter">
        Frequently Asked Questions (FAQ)
      </h1>

      {/* FAQ section for input-related questions */}
      <FaqSection heading="Input-Related FAQs" questionArr={inputFaqArr} />

      {/* FAQ section for output-related questions */}
      <FaqSection heading="Output-Related FAQs" questionArr={outputFaqArr} />

      {/* Title for Other Questions section */}
      <h1 className="text-2xl text-black font-inter font-bold my-12">
        Other Questions
      </h1>

      {/* Contact information and links */}
      <div className="">
        <span className="text-lg">Reach out to us at</span>
        <img
          src="/assets/email.jpg"
          alt="email"
          className="inline h-[20px] mb-1 mx-1"
        ></img>
        <span className="text-lg">
          with any questions about Witscript that aren't answered in these FAQs,
          our{" "}
          <a
            href="https://witscript.com/wp-content/uploads/2024/09/Terms-Conditions-Revised-09-25-2024.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-blue-600"
          >
            Terms and Conditions
          </a>{" "}
          or our{" "}
          <a
            href="https://witscript.com/wp-content/uploads/2024/09/Privacy-Policy-Revised-09-25-2024.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-blue-600"
          >
            Privacy Policy
          </a>
          .
        </span>
      </div>
    </div>
  );
};

export default FAQ;
