import React from "react";
import SignUp from "../views/signup/signUp";

const AccountSignUp = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="max-w-[1200px] w-full">
        {/* Render the SignUp component */}
        <SignUp />
      </div>
    </div>
  );
};

export default AccountSignUp;
