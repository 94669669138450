import React from "react";

// MyInput component for rendering input fields with error messages
const MyInput = ({
  name,
  placeholder,
  onChange,
  type,
  errors,
  touched,
  defaultValue,
}) => {
  return (
    <div className="mt-4 relative">
      {/* Input field */}
      <input
        name={name}
        onChange={onChange} // onChange event handler
        className={`rounded-[12px] w-full p-3 border-2 border-black placeholder-black focus:outline-none text-center hover:shadow-[7px_5px_4px_0px_#dcdcdc]`}
        type={type}
        placeholder={placeholder} // Input placeholder text
        defaultValue={defaultValue} // Default input value
      />
      {/* Error message if any */}
      {errors && touched ? <div className="text-red-500">{errors}</div> : null}
    </div>
  );
};

export default MyInput;
