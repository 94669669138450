import React, { createContext, useContext, useState, useEffect } from "react";

// Create a context for managing user login state
const LoginUserContext = createContext();

// Provider component to manage user login state
export const LoginUserProvider = ({ children }) => {
  // Retrieve initial user data and token from local storage, if available
  const initialUser = JSON.parse(localStorage.getItem("user")) || "";
  const initialToken = JSON.parse(localStorage.getItem("token")) || "";

  // State variables to store user data and token
  const [user, setUser] = useState(initialUser);
  const [token, setToken] = useState(initialToken);

  // Update local storage whenever user data or token changes
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", JSON.stringify(token));
  }, [user, token]);

  // Provide user data and token to the context
  return (
    <LoginUserContext.Provider
      value={{
        user: user, // Current user data
        setUser: setUser, // Function to update user data
        token: token, // Current user token
        setToken: setToken, // Function to update user token
      }}
    >
      {children} {/* Render child components */}
    </LoginUserContext.Provider>
  );
};

// Custom hook to access user login context
export const useLoginUser = () => {
  const context = useContext(LoginUserContext);
  // Ensure the hook is used within a LoginUserProvider
  if (!context) {
    throw new Error("useLoginUser must be used within a LoginUserProvider");
  }
  return context; // Return user login context
};
