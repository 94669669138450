import React, { useEffect } from "react";
import Layout from "../layout/layout";
import Write from "../views/home/write";
import Faq from "../views/home/faq";
import { useSearchParams } from "react-router-dom";

const Home = () => {
  // Get the URL parameters
  const [params] = useSearchParams();

  useEffect(() => {
    // Function to handle scrolling based on URL parameters
    const handleScroll = () => {
      // Get the screen width
      const screenSize = window.innerWidth;

      // Initialize the value to scroll to
      let scrollToValue;

      // Determine the scrollToValue based on screen width and URL parameter "faq"
      if (params.get("faq") === "true") {
        if (screenSize > 850) {
          scrollToValue = 1000;
        } else if (screenSize > 800) {
          scrollToValue = 1050;
        } else if (screenSize > 750) {
          scrollToValue = 1150;
        } else if (screenSize > 680) {
          scrollToValue = 1300;
        } else if (screenSize > 620) {
          scrollToValue = 1400;
        } else if (screenSize > 570) {
          scrollToValue = 1480;
        } else if (screenSize > 500) {
          scrollToValue = 1560;
        } else if (screenSize > 450) {
          scrollToValue = 1620;
        } else if (screenSize > 400) {
          scrollToValue = 1680;
        } else if (screenSize > 380) {
          scrollToValue = 1760;
        } else if (screenSize > 340) {
          scrollToValue = 1900;
        } else {
          scrollToValue = 2100;
        }
      } else {
        // If "faq" parameter is not present, scroll to the top
        scrollToValue = 0;
      }

      // Scroll to the determined value with smooth behavior
      window.scrollTo({ top: scrollToValue, behavior: "smooth" });
    };

    // Call handleScroll function when URL parameters change
    handleScroll();
  }, [params]); // Dependency array to watch for changes in URL parameters

  return (
    <div className="flex justify-center items-center w-full">
      <Layout>
        <div className="max-w-[1200px] w-full">
          {/* Render Write component */}
          <Write />
          {/* Render Faq component */}
          <Faq />
        </div>
      </Layout>
    </div>
  );
};

export default Home;
