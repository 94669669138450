import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginUser } from "../context/loginUser";

// GuestGuard component to protect routes meant for unauthenticated users
export const GuestGuard = ({ children }) => {
  // Access the current user from the login user context
  const { user } = useLoginUser();
  // Get the navigate function from react-router-dom to programmatically navigate
  const navigate = useNavigate();

  // useEffect hook to perform side effects when the component mounts or when user or navigate changes
  useEffect(() => {
    // If the user is logged in, navigate to the home page
    if (user !== "") {
      navigate("/");
    }
  }, [user, navigate]); // The effect depends on user and navigate, and runs when they change

  // Render the child components if the user is not logged in
  return <>{children}</>;
};
