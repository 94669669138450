import { navLinks } from "../constants/navLinks";
import React, { useState } from "react";
import MobileTabs from "../components/mobileTabs";
import NavLink from "../components/navLink";
import UserLogout from "../components/userLogout";

const Header = () => {
  // State to manage the mobile menu open/close state
  const [open, setOpen] = useState(false);

  return (
    // Header container with fixed position on top
    <div
      className={`py-4 fixed top-0 flex justify-center w-full bg-white ${
        open ? "" : "z-50"
      }`}
    >
      <div className={`px-4 md:px-8 max-w-[1200px] w-full`}>
        <div className="flex items-center justify-between w-full">
          {/* Logo */}
          <div>
            <img
              src="/assets/logo.png"
              alt=""
              className="w-[200px] object-cover shrink-0"
            />
          </div>
          {/* Navigation links */}
          {navLinks.map((item, index) => {
            return (
              <div key={index} className="hidden xl:block">
                {/* Render each navigation link */}
                <NavLink item={item} index={index} check={true} />
              </div>
            );
          })}
          {/* Logout button (visible on larger screens) */}
          <div className="hidden xl:block">
            <UserLogout />
          </div>
          {/* Mobile navigation tabs */}
          <MobileTabs open={open} setOpen={setOpen} />
        </div>
      </div>
    </div>
  );
};

export default Header;
