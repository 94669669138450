import { Menu, Transition } from "@headlessui/react";
import { useLoginUser } from "../context/loginUser";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const UserLogout = () => {
  // get active user, setUser and setToken
  const { user, setUser, setToken } = useLoginUser();
  // navigate to different links
  const navigate = useNavigate();

  return (
    <Menu>
      {/* Container for the user menu */}
      <div className="relative inline-block">
        {/* Button to display user's email and open the menu */}
        <Menu.Button className="font-inter inline-flex w-full justify-center rounded-md bg-[#1F4DC4] px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
          {user.email} {/* Display user's email */}
        </Menu.Button>
        {/* Menu items container */}
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          {/* Actual menu items */}
          <Menu.Items className="absolute text-center right-0 mt-2 w-full xl:w-32 h-12 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            {/* Menu item for logout */}
            <Menu.Item>
              {/* Logout button */}
              <button
                className={`w-[90%] h-8 my-2 font-medium font-inter text-gray-900 hover:bg-[#1F4DC4] hover:text-white`}
                onClick={() => {
                  // Clear user data and token
                  setUser("");
                  setToken("");
                  // Navigate to the getstarted route
                  navigate("/getstarted");
                  // Show logout success toast notification
                  toast.success("Logout successful.", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                  });
                }}
              >
                Logout
              </button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </div>
    </Menu>
  );
};

export default UserLogout;
