import React, { useState, useEffect } from "react";
import BillingInfo from "../../components/billingInfo"; // Importing BillingInfo component
import { useLoginUser } from "../../context/loginUser";
import { getToken } from "../../api/getToken"; // Importing getToken function from getToken API
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/axios-instance";
import { formatDate } from "../../utils/format-data";

const Billing = () => {
  // State variables
  const [subscription, setSubscription] = useState();
  const [link, setLink] = useState("");
  const { user, setUser, token } = useLoginUser(); // get user, setUser, and token
  const [loading, setLoading] = useState(true);

  // useEffect hook to fetch user data and subscription data
  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getToken(token);
      if (accessToken !== "error") {
        try {
          // Fetch user data
          const resp = await fetch(`${BASE_URL}/user/`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (!resp.ok) {
            throw new Error(`Error: ${resp.status} - ${resp.statusText}`);
          }

          const json = await resp.json();
          setUser(json);

          // Fetch subscription data when component mounts
          const fetchSubscription = async () => {
            try {
              const resp2 = await fetch(`${BASE_URL}/stripe/subscription/`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });
              const json2 = await resp2.json();
              if (json2.type === "request_error") {
                setSubscription(null);
                setLoading(false);
                return;
              }

              if (!resp2.ok) {
                throw new Error(`Error: ${resp2.status} - ${resp2.statusText}`);
              }
              console.log("subscription: ", json2?.subscription);
              setSubscription(json2?.subscription);
              setLoading(false);
              try {
                const resp3 = await fetch(`${BASE_URL}/stripe/cportal/`, {
                  method: "POST",
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                });
                const json3 = await resp3.json();

                if (!resp3.ok) {
                  throw new Error(
                    `Error: ${resp3.status} - ${resp3.statusText}`
                  );
                }
                console.log("url: ", json3?.url);
                setLink(json3?.url);
              } catch (error) {
                console.error("Error fetching cPortal:", error);
              }
            } catch (error) {
              console.error("Error fetching subscription:", error);
              setLoading(false);
            }
          };
          setLoading(false);

          if (json?.subscription_id) {
            fetchSubscription();
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
          return "Some Error occurs. Please try again.";
        }
      } else {
        toast.error(
          "Your session has expired. Please logout, then login again.",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "colored",
            progress: undefined,
          }
        );
      }
    };

    if (user !== "") {
      fetchData();
    }

    // Fetch data every 5 seconds
    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, []);

  // Function to render subscription status
  const renderSubscriptionStatus = () => {
    if (!subscription || subscription?.status === "canceled") {
      return <p className="text-lg font-bold text-black">Subscribe Now</p>;
    } else if (subscription?.status === "active") {
      if (subscription.cancel_at_period_end) {
        const endDate = subscription.current_period_end;
        return (
          <p className="text-lg font-semibold text-black">
            Subscription End At: {formatDate(endDate)}
          </p>
        );
      } else {
        const nextBilledDate = subscription.current_period_end;
        return (
          <div className="flex w-full justify-between">
            <p className="text-lg font-semibold text-black">Current Plan</p>
            <p className="text-lg font-semibold text-black">
              Next Billed At: {formatDate(nextBilledDate)}
            </p>
          </div>
        );
      }
    }
  };

  // If data is loading, render Loading...
  if (loading) {
    return <p className="text-lg">Loading...</p>;
  }

  // Render billing information
  return (
    <div className="mb-8">
      <div className="max-w-[600px] w-full">
        <p className="text-lg font-inter font-semibold text-black mb-8">
          Our payment processor, Stripe, will help you manage your subscription.
        </p>
        <div className="border border-gray2 rounded-[5px]">
          <div className="flex justify-between items-center gap-y-4 flex-col md:flex-row p-4 bg-gray1 border border-gray2 rounded-[5px]">
            {renderSubscriptionStatus()}
            <div>
              {/* if not subscribe, then provide subscribe option */}
              {subscription?.status !== "active" && (
                <a
                  // href={`https://buy.stripe.com/test_00gg0j4CI1oa8yA000?client_reference_id=${user.pk}&prefilled_email=${user.email}`}
                  href={`https://buy.stripe.com/28o02UbUV4S76Fa7ss?client_reference_id=${user.pk}&prefilled_email=${user.email}`}
                  target="_blank"
                  className="text-third px-4 py-[6px] border-2 border-third hover:bg-third hover:text-white rounded-[5px] font-bold  hover:shadow-[7px_5px_4px_0px_#dcdcdc]"
                >
                  Subscribe
                </a>
              )}
            </div>
          </div>
          <div className="flex px-4 py-8 justify-between flex-col sm:flex-row gap-y-4">
            <BillingInfo title="PLAN NAME" value="Basic Plan" />
            <BillingInfo title="BILLING CYCLE" value="Monthly" />
            <BillingInfo title="PLAN COST" value="$5.99" />
          </div>
        </div>
        {subscription?.status === "active" &&
          !subscription?.cancel_at_period_end && (
            <div>
              {/* update subscription link */}
              <p className="mt-6 font-inter text-black">
                To update your payment method or cancel your subscription,
                please click{" "}
                <a
                  href={link}
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  here
                </a>
                .
              </p>
            </div>
          )}
      </div>
    </div>
  );
};

export default Billing;
