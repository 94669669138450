import React from "react";
import GetStartedView from "../views/getStarted/getStarted";

const GetStarted = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="max-w-[1200px] w-full">
        {/* Render the GetStartedView component */}
        <GetStartedView />
      </div>
    </div>
  );
};

export default GetStarted;
