import React from "react";
import ResetPassword from "../views/password/resetPassword";

const Password = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="max-w-[1200px] w-full">
        {/* Render the ResetPassword component */}
        <ResetPassword />
      </div>
    </div>
  );
};

export default Password;
