// input faqs data
export const inputFaqArr = [
  {
    question: "What types of input text tend to result in the best jokes?",
    answer:
      "To get the best jokes out of Witscript, input text that you think a witty human might respond to with a joke. The most productive inputs tend to be sentences that would grab most people's attention, possibly because they're surprising in some way.",
  },
  {
    question:
      "Are there any character or word limits for the text that I input?",
    answer:
      "There are no character limits, but your input text must be from 5 to 25 words long. Those word limits help Witscript craft the best jokes.",
    answer2:
      "That's because if your input text is any shorter, it probably won't offer Witscript enough interesting details to base its jokes on. Any longer, and Witscript may have a harder time deciding which details to focus on.",
  },
  {
    question: "Should I format my input text in any particular way?",
    answer:
      "Your input text should consist of one, or possibly two, statements or questions. As much as you can, use proper punctuation, capitalization and grammar. That way, the meaning of your input text will be as clear as possible.",
  },
  {
    question:
      "How can I get Witscript to generate a joke about a current event?",
    answer:
      "Input a sentence summarizing the event, maybe the headline or first sentence of a news item; fill in any missing words so it sounds conversational. Witscript's best response, when added after your input text, can complete a topical joke.",
  },
  {
    question: "How much does Witscript know about current events?",
    answer:
      "Right now Witscript is using a large language model with a cutoff date of September 2021 for its training data. As a result, Witscript may not be aware of events that took place after that.",
    answer2:
      "But if your input text refers to a current event, Witscript may still be able to generate jokes about it. That's because Witscript creates its jokes by using words and phrases associated with details of the input text, and those associations usually don't change much over time.",
  },
  {
    question: "How can Witscript help me liven up a speech, ad, or article?",
    answer:
      "You can use Witscript to add humor to any written material that needs some lightening up. Just input a sentence from the text that seems to invite a follow-on joke. Witscript will generate options for a joke that you can insert.",
  },
  {
    question:
      "How can I use Witscript to compose clever comments for social media?",
    answer:
      "Would you like to respond to something you read online in a way that makes people smile? Input the remark that you’re responding to and Witscript will suggest witticisms that you can post as-is or adapt.",
  },
  {
    question:
      "How can I use Witscript to add fresh material to a standup comedy routine?",
    answer:
      "Have you noticed something interesting, surprising, annoying, weird or dumb in the world that’s begging for a joke? Maybe something in your life? Input your observation into Witscript and let it pitch possible ways to complete the joke.",
  },
  {
    question: "How can Witscript help me come up with jokes for a sketch?",
    answer:
      'Many sketches are a "joke basket," a collection of jokes related by a theme. You can use Witscript to help you write those jokes.',
    answer2:
      "For example, say you're writing a joke basket in which Amazon's Alexa responds humorously to a series of typical questions from its users. Just input those questions into Witscript and let it provide Alexa's responses.",
  },
  {
    question:
      "How can I create funny captions for cartoons or other images with Witscript?",
    answer:
      "To generate a funny caption for an image, input into Witscript a relevant question that includes the image’s most important details. Your question should only include the most attention-getting people/places/things in the image, where they are, and what they're doing.",
    answer2:
      'For example, for a particular cartoon you could input "What would a doctor in an examination room say to Mr. Potato Head?" By responding to that question, Witscript will suggest possibilities for a funny caption.',
  },
  {
    question: "How can Witscript give a chatbot or robot a sense of humor?",
    answer:
      "The Witscript system is patented (U.S. Patent Nos. 10,642,939; 10,878,817; and 11,080,485) and is available for license. You could integrate Witscript into a chatbot or a conversational robot as a humor module. So when the user says something, if humor would be appropriate, Witscript would enable the chatbot or robot to respond with a relevant joke.",
    answer2:
      "With Witscript providing a humanlike sense of humor, the chatbot or robot could serve as a likeable and engaging artificial companion.",
  },
  {
    question: "What languages does Witscript support?",
    answer:
      "Witscript is designed to work in American English. But it's powered by a large language model that has demonstrated unexpected abilities. So if you input text in a language other than English, Witscript may still respond with some jokes. Why not try it?",
  },
];
