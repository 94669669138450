import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/axios-instance";

const EmailVerify = ({ isOpen, closeModal, email }) => {
  const [key, setKey] = useState("");
  const navigate = useNavigate();

  const checkValid = async () => {
    if (key.trim() !== "") {
      // call verify email api
      try {
        const resp = await fetch(`${BASE_URL}/registration/verify-email/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            key: key,
          }),
        });

        if (!resp.ok) {
          const errorResponse = await resp.json();
          const errorMessage = Object.values(errorResponse)[0];
          console.error(
            `Error: ${resp.status} - ${resp.statusText} - ${errorMessage}`
          );
          // display error message if occur
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "colored",
            progress: undefined,
          });
          throw new Error(`Error: ${resp.status} - ${resp.statusText}`);
        }

        // navigate to login after successfully creating account
        navigate("/auth/login");
        toast.success("Account Created", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored",
          progress: undefined,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        return "Some Error occurs. Please try again.";
      }
    } else {
      // Display error if try to submit empty key
      toast.error("Empty Key", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
  };

  const resendEmail = async () => {
    // call the resend email api
    try {
      const resp = await fetch(`${BASE_URL}/registration/resend-email/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      });

      if (!resp.ok) {
        const errorResponse = await resp.json();
        const errorMessage = Object.values(errorResponse)[0];
        console.error(
          `Error: ${resp.status} - ${resp.statusText} - ${errorMessage}`
        );
        // display error message if occur
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored",
          progress: undefined,
        });
        throw new Error(`Error: ${resp.status} - ${resp.statusText}`);
      }

      toast.success("Verification Email Sent.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      return "Some Error occurs. Please try again.";
    }
  };

  return (
    // model that verify email and resend verification email
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 mb-4 text-gray-900"
                >
                  Please enter the (case-sensitive) confirmation code that we
                  sent to your email address. Can't find our email? Check your
                  spam folder.
                </Dialog.Title>
                <input
                  type="text"
                  id="key"
                  placeholder="Enter confirmation code"
                  value={key}
                  autoComplete="off"
                  className="w-full py-2 border-2 border-black focus:outline-none rounded-lg px-4"
                  onChange={(e) => {
                    setKey(e.target.value);
                  }}
                />
                <div className="mt-4 flex gap-4 justify-end">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent px-6 py-2 text-sm font-medium bg-[#1F4DC4] text-white hover:shadow-[7px_5px_4px_0px_#dcdcdc] hover:scale-[1.025] transition-all duration-300"
                    onClick={resendEmail}
                  >
                    Resend
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent px-8 py-2 text-sm font-medium bg-[#1F4DC4] text-white hover:shadow-[7px_5px_4px_0px_#dcdcdc] hover:scale-[1.025] transition-all duration-300"
                    onClick={checkValid}
                  >
                    Confirm
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EmailVerify;
